(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('ProjectionsController', ProjectionsController);

    ProjectionsController.$inject = ['$scope', '$state', 'LoginService', 'Principal', 'roundInfo', 'DataUtils', 'Resource'];

    function ProjectionsController($scope, $state, LoginService, Principal, roundInfo, DataUtils, Resource) {
        var vm = this;
        vm.$state = $state;
        vm.roundInfo = roundInfo;
        vm.allRounds = [
            {date: "September 2022", report: 518, db: 517, gui: 0},
            {date: "February 2019", report: 502, db: 501, gui: 493},
            {date: "January 2019", report: 500, db: 499, gui: 0},
            {date: "December 2018", report: 498, db: 497, gui: 0},
            {date: "November 2018", report: 496, db: 495, gui: 494},
            {date: "October 2018", report: 492, db: 491, gui: 0}
        ];
        vm.oldRounds = vm.allRounds.splice(1);
        vm.downloadResource = downloadResource;

        //***************************************************************
        // basic auth operations
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        //***************************************************************
        // if (!vm.$state.includes('charts')) {
        $state.go('charts', {countryCode: 'US'})

        // }

        /**
         * Triggers downloading -- either proper resource downloading from SVN or a data export
         * @param resId      resource ID
         * @param fileName   the name of file to store the downloaded data into
         */
        function downloadResource(resId, fileName) {
            Resource
                .download({id: resId, download: true})
                .$promise
                .then(function (response) {
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName);
                });
        }
    }
})();
