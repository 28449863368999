(function () {
    'use strict';

    angular
        .module('portalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                homeChartSeries: ['$q', 'TimeseriesDataMnemonicOpen', function ($q, TimeseriesDataMnemonicOpen) {
                    var seriesList = [
                        {name: 'D4L_GDP_WO', prefix: "DOT4"},
                        {name: 'D4L_CPI_WO', prefix: "DOT4"}
                        ];
                    var promArr = [];
                    for (var ix = 0; ix < seriesList.length; ix++) {
                        promArr.push(TimeseriesDataMnemonicOpen.query({
                            "actionCode": 'sm',
                            "baseSeriesName": seriesList[ix].name,
                            "mnemoPrefix": seriesList[ix].prefix
                        }).$promise);
                    }
                    return $q.all(promArr);
                }]
            }
        });
    }
})();
