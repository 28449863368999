(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('ChartsController', ChartsController);

    ChartsController.$inject = ['$state', 'projChartSeries', 'roundInfo'];

    function ChartsController($state, projChartSeries, roundInfo) {
        var vm = this;
        vm.$state = $state;
        vm.loadingContent = true;
        vm.dateFrequency = 4;
        vm.shadowRange = [{
            end: roundInfo.lastHist
        }];
        vm.chartEndDate = new Date(roundInfo.lastHist.getFullYear() + 2, roundInfo.lastHist.getMonth() + 9, 1);
        vm.chartMinDate = new Date(roundInfo.lastHist.getFullYear() - 10, roundInfo.lastHist.getMonth(), 1);
        vm.chart1 = {
            chartId: "Chart01",
            title: "Real GDP Growth (% YoY)",
            lineSeries: []
        };
        // remove all the unnecessary object properties
        vm.chart1.lineSeries.push(projChartSeries[0].map(function (tsData) {
            return {date: tsData.date, value: tsData.value};
        }));

        vm.chart2 = {
            chartId: "Chart02",
            title: "Headline CPI Inflation (% YoY)",
            lineSeries: []
        };
        // remove all the unnecessary object properties
        vm.chart2.lineSeries.push(projChartSeries[1].map(function (tsData) {
            return {date: tsData.date, value: tsData.value};
        }));

        vm.chart3 = {
            chartId: "Chart03",
            title: "Short Interest Rate (% p.a.)",
            lineSeries: []
        };
        // remove all the unnecessary object properties
        vm.chart3.lineSeries.push(projChartSeries[2].map(function (tsData) {
            return {date: tsData.date, value: tsData.value};
        }));

        vm.loadingContent = false;
    }
})();
