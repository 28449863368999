(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$http', '$scope', '$state', '$uibModal', 'homeChartSeries', 'roundInfo'];

    function HomeController($http, $scope, $state, $uibModal, homeChartSeries, roundInfo) {
        var vm = this;

        vm.loadingContent = true;
        vm.dateFrequency = 4;
        vm.shadowRange = [{
            end: roundInfo.lastHist
        }];
        vm.chartEndDate = new Date(roundInfo.lastHist.getFullYear() + 2, roundInfo.lastHist.getMonth() + 9, 1);
        vm.chartMinDate = new Date(roundInfo.lastHist.getFullYear() - 10, roundInfo.lastHist.getMonth(), 1);
        // vm.chartMinDate = new Date(vm.latestEwsRound.created.getFullYear(), vm.latestEwsRound.created.getMonth() - 42, 1);
        vm.chart1 = {
            chartId: "Chart01",
            title: "World GDP Growth Rate (% YoY)",
            lineSeries: []
        };
        // remove all the unnecessary object properties
        vm.chart1.lineSeries.push(homeChartSeries[0].map(function (tsData) {
            return {date: tsData.date, value: tsData.value};
        }));
        vm.chart2 = {
            chartId: "Chart02",
            title: "World CPI Inflation (% YoY)",
            lineSeries: []
        };
        // remove all the unnecessary object properties
        vm.chart2.lineSeries.push(homeChartSeries[1].map(function (tsData) {
            return {date: tsData.date, value: tsData.value};
        }));
        vm.loadingContent = false;

    }
})();
