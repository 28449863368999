(function () {
    'use strict';

    angular
        .module('portalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('projections', {
            parent: 'app',
            url: '/projections',
            sticky: true,
            dsr: true,
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/portal/projections/projections.html',
                    controller: 'ProjectionsController',
                    controllerAs: 'vm'
                },
                'actual@projections': {
                    templateUrl: 'app/portal/projections/actual.html'
                },
                'archive@projections': {
                    templateUrl: 'app/portal/projections/archive.html'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('charts', {
                parent: 'projections',
                url: '',
                params: {
                    countryCode: 'US'
                },
                views: {
                    'charts': {
                        templateUrl: 'app/portal/projections/charts.html',
                        controller: 'ChartsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    projChartSeries: ['$stateParams', '$q', 'TimeseriesDataMnemonicOpen', function ($stateParams, $q, TimeseriesDataMnemonicOpen) {
                        if ($stateParams.countryCode === null) {
                            return $q.when([]);
                        }
                        var seriesList = [
                            {name: 'D4L_GDP_' + $stateParams.countryCode, prefix: "DOT4"},
                            {name: 'D4L_CPI_' + $stateParams.countryCode, prefix: "DOT4"},
                            {name: 'RS_' + $stateParams.countryCode, prefix: "NC"}
                        ];
                        var promArr = [];
                        for (var ix = 0; ix < seriesList.length; ix++) {
                            promArr.push(TimeseriesDataMnemonicOpen.query({
                                "actionCode": 'sm',
                                "baseSeriesName": seriesList[ix].name,
                                "mnemoPrefix": seriesList[ix].prefix
                            }).$promise);
                        }
                        return $q.all(promArr);
                    }]
                }
            });
    }
})();

