(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['$scope', '$state', 'FooterService', 'moment'];

    function FooterController ($scope, $state, FooterService, moment) {
        var vm = this;

        vm.applicationVersion = "";
        vm.year = moment().format('YYYY');

        FooterService.get().$promise.then(function(response) {
            vm.applicationVersion = response.applicationVersion;
        });
    }
})();
